.cta {
  padding: 6rem 0;
  @media screen and (max-width: 767px) {
    padding: 3rem 0;
  }
  &__content {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }

  &__titulo {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    @media screen and (max-width: 767px) {
      font-size: 2.2rem;
    }
    @media screen and (max-width: 400px) {
      font-size: 2rem;
    }
    span {
      padding: 0rem 1rem;
      @media screen and (max-width: 767px) {
        padding: 0rem 0.5rem;
      }
      @media screen and (max-width: 400px) {
        padding: 0rem 0.5rem;
      }
    }
  }

  &__links {
    // width: 45%;
    // width: ;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    @media screen and (max-width: 767px) {
      width: 100%;
      flex-direction: column;
    }
  }

  &__link {
    margin: 0 1rem 0.5rem;
    display: inline-block;
  }

  &__link--01 {
  }

  &__link--02 {
    a {
      color: #1e8479;
    }
  }

  &__link--03 {
    a {
      color: $color-gris-04;
    }
  }
  hr {
    color: $color-gris-04;
    width: 10%;
    height: 2px;
    margin-bottom: 2rem;
  }
}
