.header {
  padding: 2rem 3rem;
  position: sticky;
  top: 0;
  background-color: $color-background-light;
  transition: $trans;
  z-index: 3;
  @media screen and (max-width: 767px) {
    padding: 1rem 1rem;
  }
  &--pages {
    @media screen and (max-width: 575px) {
      background-color: $color-principal;
    }
  }
  &--sticky {
    background-color: $color-background;
    padding: 0.5rem 3rem;
    box-shadow: $box-shadow;
    transition: $trans;
    @media screen and (max-width: 767px) {
      padding: 0.5rem 1rem;
    }
    .header__logo {
      transition: $trans;
      img {
        transition: $trans;
        @media screen and (max-width: 767px) {
          width: 35px;
          height: auto;
          transition: $trans;
        }
      }
    }
  }

  &__container {
  }
  &__navbar {
    display: flex;
    align-items: center;
  }
  &__left {
    display: flex;
  }
  &__right {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
  &__burger {
    font-size: 30px;
    font-weight: 700;
  }
  &__logo {
    display: flex;
    align-items: center;
    img {
      width: 48px;
      height: auto;
      transition: $trans;
    }
  }
  &__nav {
    margin-left: 3rem;
    display: flex;
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
}

.left-title {
  position: absolute;
  display: inline-flex;
  top: 13.5rem;
  text-align: right;
  left: -44px;
  font-size: 1rem;
  transform: rotate(-90deg);
  font-weight: normal;
  @media screen and (max-width: 1100px) {
    display: none;
  }
}

.menu-principal {
  display: flex;
  align-items: center;
  margin-top: 0.6rem;
  list-style: none;
  .menu-item.current-menu-item {
    a {
      color: $color-alternativo;
    }
  }
  .menu-item {
    a {
      padding: 0rem 1rem;
      font-weight: normal;
      color: $color-gris-04;
      &:hover {
        color: $color-alternativo;
      }
    }
  }
}

.btn-overlay {
  width: auto;
  height: auto;
  cursor: pointer;
  display: inline-block;
}

.body-scroll {
  overflow: hidden;
}

.m-overlay {
  display: none;
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgb(46, 46, 46);
  color: $color-background;
  overflow: auto;
  z-index: 10;
  padding: 5rem;

  @media screen and (max-width: 575px) {
    padding: 1.5rem;
  }
  &__header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__close {
    position: absolute;
    top: 2rem;
    right: 3rem;
    @media screen and (max-width: 767px) {
      top: 0.5rem;
      right: 1.5rem;
    }
    .bi {
      font-size: 4rem;
      cursor: pointer;
      // transform: scale(1.5);
    }
  }
  &__menu {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    width: 100%;
    position: relative;
    margin-top: 4rem;
  }
  &__nav {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // flex-direction: column;
    padding-left: 0;
    list-style: none;
    margin-bottom: 6rem;
    text-align: center;
    @media screen and (max-width: 575px) {
      margin-bottom: 4rem;
    }
    @media screen and (max-width: 375px) {
      margin-bottom: 1rem;
    }

    li {
      margin-bottom: 1rem;
      @media screen and (max-width: 575px) {
        margin-bottom: 1rem;
      }
      a {
        // color: $color-background;
        color: $color-background;
        text-decoration: none;
        font-size: 6rem;
        line-height: 1.2;
        font-weight: bold;
        @media screen and (max-width: 767px) {
          font-size: 3rem;
        }
        @media screen and (max-width: 375px) {
          font-size: 2rem;
          font-family: $typo-principal;
        }
        .sub {
          display: block;
          font-size: 1.2rem;
          font-weight: 400;
          color: $color-principal;
          @media screen and (max-width: 767px) {
            font-size: 1rem;
          }
        }
      }
    }
  }
}
