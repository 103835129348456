@mixin ml-home {
  margin-left: 10%;
  @media screen and (max-width: 767px) {
    margin-left: 0%;
  }
}

@mixin pad-page {
  padding: 2rem 3rem;
  @media screen and (max-width: 767px) {
    padding: 1rem 1rem;
  }
}

@mixin titulo {
  font-size: 5rem;
  line-height: 1;
  font-weight: bold;
  width: 40%;
  margin-bottom: 2rem;
  @media screen and (max-width: 767px) {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
}
