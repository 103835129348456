.identidad {
  background: linear-gradient(
    90deg,
    $color-principal 0%,
    $color-principal 70%,
    #efefef 70%,
    #efefef 100%
  );
  &__container {
  }
  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
      flex-direction: column-reverse;
    }
  }
  &__claim {
    @include ml-home;
  }
  &__imagen {
    @media screen and (max-width: 767px) {
      margin-bottom: 1rem;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
  &__info {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    @media screen and (max-width: 767px) {
      display: flex;
      flex-direction: column-reverse;
      gap: 1rem;
    }

    p {
      display: inline-block;
      width: 50%;
      @media screen and (max-width: 767px) {
        width: 100%;
        // margin-bottom: 0rem;
      }
    }
  }
  &__titulo {
    @include titulo;
  }
  &__imagen-left {
    right: 92%;
    position: absolute;
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
}

.detalles {
  &__container {
    @include ml-home;
  }
  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
      flex-direction: column-reverse;
    }
  }
  &__claim {
  }
  &__info {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    @media screen and (max-width: 767px) {
      display: flex;
      flex-direction: column-reverse;
      gap: 1rem;
    }

    p {
      display: inline-block;
      width: 60%;
      @media screen and (max-width: 767px) {
        width: 80%;
      }
    }
  }
  &__imagen {
    margin-bottom: 1rem;
    img {
      width: 100%;
      height: auto;
    }
  }
  &__titulo {
    @include titulo;
  }
}

.rafel {
  background: linear-gradient(
    90deg,
    #efefef 0%,
    #efefef 40%,
    $color-principal 40%,
    $color-principal 100%
  );

  @media screen and (max-width: 767px) {
    background: $color-principal;
  }

  &__container {
    @include ml-home;
  }

  &__claim {
    width: 70%;
    font-size: 3.3rem;
    margin-bottom: 2rem;
    @media screen and (max-width: 767px) {
      font-size: 2.2rem;
      width: 100%;
    }
    h3 {
      display: inline-block;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
    span {
      font-weight: bold;
      display: inline-block;
    }
  }

  &__links {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 70%;
    margin-bottom: 5rem;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    a:nth-child(2) {
      color: $color-gris-04;
      font-weight: normal;
      &:hover {
        color: $color-alternativo;
      }
    }
  }

  &__content {
    width: 90%;
    display: grid;
    gap: 2rem;
    grid-template-columns: auto 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      "a a b"
      "a a c";
    @media screen and (max-width: 767px) {
      width: 100%;
      display: flex;
      flex-direction: column-reverse;
      gap: 1rem;
    }
  }
  .item1 {
    grid-area: a;
    @media screen and (max-width: 767px) {
      display: none;
    }
    img {
      width: 100%;
      height: 550px;
      object-fit: cover;
    }
  }
  .item2 {
    grid-area: b;
    text-align: right;
    @media screen and (max-width: 767px) {
      text-align: left;
    }
    p {
      display: block;
      text-align: right;
      // margin-left: 60%;
      @media screen and (max-width: 767px) {
        text-align: left;
        margin-left: 0%;
        width: 70%;
      }
    }
    a {
      display: inline-block;
    }
  }
  &__titulo {
    @include titulo;
    width: 100%;
  }
  .item3 {
    grid-area: c;
    @media screen and (max-width: 767px) {
      margin-bottom: 1.5rem;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
}

.bi .bi-chevron-right {
  font-weight: bold !important;
}
