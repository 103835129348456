.wpcf7 {
  h3 {
    font-size: 1.2rem;
    margin-bottom: 1.2rem;
  }
  fieldset {
    margin-bottom: 1.2rem;
  }
  .wpcf7-acceptance {
    font-size: $font-size-smaller;
    color: $color-gris-02;
    a {
      color: $color-gris-02;
      font-weight: 400;
      text-decoration: underline;
    }
  }

  .wpcf7-submit {
    border: none;
    transition: $trans;
    &[disabled=""] {
      color: $color-gris-02;
      background-color: $color-gris-01;
      transition: $trans;
    }
    @media screen and (max-width: 767px) {
      display: block;
      width: 100%;
      text-align: center;
      padding: 1.2rem 2rem;
      font-size: 1.2rem;
    }
  }

  .wpcf7-response-output {
    margin: 1em 0 1em !important;
    padding: 0.5em 1em !important;
    border-radius: 8px !important;
  }
}

.form-control,
.form-select {
  padding: 0.675rem 0.75rem;
  font-size: 1rem !important;
}

::-webkit-input-placeholder {
  /* WebKit browsers */
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
}
:-ms-input-placeholder {
  /* Internet Explorer 10+ */
}

body:not(.page-template-contacto) .grecaptcha-badge {
  visibility: hidden;
}
