.page-title {
  &__container {
    padding: 0rem;
  }
  &__content {
    background-color: $color-principal;
    @include pad-page;
    @media screen and (max-width: 767px) {
      padding-top: 0rem;
    }
  }

  &__h {
    font-size: 3rem;
    @media screen and (max-width: 767px) {
      font-size: 3rem;
      font-weight: bold;
    }
  }
  &__breadcrumbs {
    font-size: $font-size-small;
  }
}
