/* SCSS Botones
--------------------------------------------------- */
.button {
  padding: 0.7rem 2rem;
  display: inline-flex;
  align-items: center;
  transition: $trans;
  font-size: $font-size;
  font-weight: normal;
  &:hover {
    transition: $trans;
  }
  i {
    margin-left: 6px;
  }
}

.button--principal {
  background-color: $color-principal;
  margin-right: 2rem;
  color: $color-gris-04;
  &:hover {
    color: $color-gris-04;
    background-color: darken($color: $color-principal, $amount: 10%);
  }
}

.button--reservas {
  background-color: $color-principal-dark;
  margin-right: 2rem;
  color: $color-background;
  &:hover {
    color: $color-background !important;
    background-color: darken($color: $color-principal-dark, $amount: 10%);
  }
}

.button--sm {
  @media screen and (max-width: 767px) {
    padding: 0.4rem 1.5rem;
    margin-right: 1rem;
  }
}

.button--whats {
  background-color: #00bb2d;
  margin-right: 2rem;
  color: $color-background;
  &:hover {
    background-color: #00bb2d;
    color: $color-background;

    filter: brightness(0.9);
  }
}

.link-arrow {
  display: inline-flex;
  align-items: center;
  i {
    margin-left: 6px;
  }
  &--red {
    color: $color-alternativo;
  }
}
