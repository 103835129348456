.idiomas {
  @media screen and (max-width: 767px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  li {
    button {
      background-color: $color-gris-02 !important;
      color: $color-background;
      border: 0 !important;
      display: flex;
      align-items: center;
      @media screen and (max-width: 767px) {
        width: 100%;
        text-align: center;
        justify-content: center;
      }
      &.active {
        background-color: $color-principal-dark !important;
        color: $color-background !important;
      }
      img {
        width: 22px;
        height: auto;
        margin-right: 0.5rem;
        border: 1px solid rgba($color: #fff, $alpha: 1);
        border-radius: 50px;
        padding: 2px;
      }
    }
  }
}

.carta {
  &__container {
    @include pad-page;
    width: 100%;
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 4rem !important;
    gap: 10rem;
    margin-bottom: 4rem;
    @media screen and (max-width: 992px) {
      display: flex;
      flex-direction: column;
      row-gap: 2.5rem !important;
    }
  }
  &__item {
    background-color: $color-background;
    padding: 2rem;
    box-shadow: 2px 2px 5px rgba($color: #000000, $alpha: 0.1);
    @media screen and (max-width: 767px) {
      padding: 1rem;
    }
  }
  &__titulo {
    font-size: 3rem;
    line-height: 1;
    font-weight: bold;
    margin-bottom: 2rem;
    @media screen and (max-width: 767px) {
      font-size: 2.5rem;
      margin-bottom: 1rem;
      color: $color-principal-dark;
    }
    small {
      font-size: 50%;
    }
  }
  &__logo {
    width: 130px;
    height: auto;
  }
  &__plato {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
    border-bottom: dotted 4px $color-principal;
    @media screen and (max-width: 767px) {
      font-size: 14px;
    }
    &:last-child {
      border-bottom: none;
    }
    span {
      font-size: 0.8125rem;
      font-weight: normal;
    }
  }
  &__recomendacion {
    span {
      background-color: rgb(255, 183, 0);
      font-size: 0.95rem;
      padding: 2px 5px;
      border-radius: 4px;
      display: inline-flex;
      align-items: center;
      color: #fff;
      color: $color-gris-03;
      margin-bottom: 5px;
    }
  }
  &__nombre {
    width: 80%;
    font-weight: bold;
    @media screen and (max-width: 767px) {
      font-size: 1.1rem;
      line-height: 1.2;
    }
  }
  &__vino {
    font-size: $font-size;
    font-weight: 400;
    margin-top: 5px;
    color: rgba($color: #000000, $alpha: 0.7);
    &-variedad {
    }
    &-do {
      margin-top: 4px;
      // font-size: $font-size-small;
      text-transform: uppercase;
      // font-weight: 500;
    }
  }
  &__precio {
  }

  &__menu-tipo {
    margin-bottom: 1rem;
    padding: 1rem 0;
    border-bottom: dotted 4px $color-principal;
  }

  &__menu-tipo-titulo {
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    font-size: 1.2rem;
    letter-spacing: 1px;
    color: $color-principal-dark;
    font-weight: bold;
    @media screen and (max-width: 767px) {
      margin-bottom: 0.8rem;
    }
  }

  &__menu-tipo-platos {
  }

  &__menu-tipo-plato {
    margin-bottom: 10px;
    @media screen and (max-width: 767px) {
      margin-bottom: 12px;
      font-size: 1.1rem;
      line-height: 1.2;
    }
  }
}

.alergenos {
  @media screen and (min-width: 576px) {
    display: none;
  }
  text-align: center;
  margin-top: 3rem;
}
