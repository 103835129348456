.contacto {
  &__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    @media screen and (max-width: 767px) {
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }
  }

  &__datos {
  }

  &__titulo {
    margin-bottom: 2rem;
    h3 {
      width: 100%;
      margin-bottom: 0.5rem;
      font-size: 2rem;
      line-height: 1;
      font-weight: bold;
      color: $color-principal-dark;

      @media screen and (max-width: 767px) {
        margin-bottom: 0.3rem;
      }
      @media screen and (max-width: 400px) {
        font-size: 2.3rem;
      }
    }
    p {
      color: $color-gris-02;
    }
  }

  &__content {
    margin-bottom: 3rem;
  }

  &__item {
    margin-bottom: 1.5rem;

    ul {
      padding-left: 0;
      margin-bottom: 0;
      list-style: none;
      li {
        span {
          display: inline-block;
          min-width: 100px;
        }
      }
    }

    h3 {
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
      font-weight: bold;
    }
  }

  &__form {
  }
}

.map {
  iframe {
    filter: grayscale(100%);
  }
}
