.cocina {
  &__container {
    @include pad-page;
    width: 100%;
  }

  &__content {
    display: flex;
    justify-content: flex-start;
  }

  &__claim {
    width: 70%;
    @media screen and (max-width: 991px) {
      width: 100%;
    }
    h3 {
      font-size: 3.3rem;
      // margin-bottom: 2rem;
      @media screen and (max-width: 767px) {
        font-size: 2.5rem;
        margin-bottom: 0rem;
      }
      @media screen and (max-width: 400px) {
        font-size: 2rem;
        margin-bottom: 0rem;
      }
      span {
        font-weight: bold;
        display: inline-block;
      }
    }
  }
}

.cocina-identidad {
  background: linear-gradient(
    90deg,
    #efefef 0%,
    #efefef 40%,
    $color-principal 40%,
    $color-principal 100%
  );
  &__container {
    // @include ml-home;
    width: 100%;
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__foto {
    margin-bottom: 2.5rem;
    img {
      width: 100%;
      height: auto;
    }
  }

  &__info {
    max-width: 775px;
  }

  &__titulo {
    @include titulo;
    width: 100%;
  }

  &__texto {
    width: 80%;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    br {
      margin-bottom: 1rem;
    }
  }
}

.galeria {
  &__container {
  }

  &__content {
    display: grid;
    gap: 8px;
    grid-template-columns: repeat(4, 1fr);
    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(2, 1fr);
      gap: 4px;
    }
  }

  &__item {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &--1 {
      @media screen and (min-width: 992px) {
        grid-column: 1 / 3;
        grid-row: 1 / 3;
      }
    }
    &--2 {
      @media screen and (min-width: 992px) {
        grid-column: 3 / 5;
        grid-row: 2 / 4;
      }
    }
    &--3 {
      @media screen and (min-width: 992px) {
        grid-column: 2 / 3;
        grid-row: 3 / 5;
      }
    }
    // &--4 {
    //   @media screen and (min-width: 992px) {
    //     grid-column: 4 / 5;
    //     grid-row: 5 / 7;
    //   }
    // }
  }
}
