.hero {
  // height: 80%;
  padding: 8rem 0;
  @media screen and (max-width: 767px) {
    padding: 2rem 0 3rem;
  }
  &__container {
    height: 100%;
    width: 80%;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
  &__restaurante {
    @media screen and (min-width: 992px) {
      display: none;
    }
    font-size: $font-size;
    margin-bottom: none;
    color: $color-principal-dark;
  }
  &__claim {
    width: 50%;
    margin-bottom: 2rem;
    font-size: 9rem;
    font-weight: bold;
    line-height: 1;
    margin-top: 0;
    @media screen and (max-width: 767px) {
      margin-top: 1rem;
      margin-bottom: 1rem;
      width: 100%;
      font-size: 3rem;
    }
  }
  &__actions {
    width: auto;
    margin-bottom: 3rem;
    @media screen and (max-width: 767px) {
      margin-bottom: 2rem;
    }
    a {
      color: $color-gris-04;
      font-weight: normal;
      padding: 0rem 1rem;
      @media screen and (max-width: 767px) {
        padding: 0rem 0.5rem;
      }
      &:hover {
        color: $color-alternativo;
      }
    }
    a:first-child {
      padding-left: 0rem;
    }
  }
  &__descripcion {
    font-size: 1.8rem;
    width: 80%;
    font-weight: normal;
    @media screen and (max-width: 767px) {
      width: 100%;
      font-size: 1.5rem;
      color: $color-gris-03;
    }
  }
}

// .hero-left {
//   position: relative;
//   &__text {
//     position: absolute;
//     left: -85px;
//     top: -320px;
//     & h3 {
//       font-size: 1rem;
//       transform: rotate(-90deg);
//       font-weight: normal;
//     }
//   }
// }

// .hero-right {
//   position: relative;
//   &__text {
//     position: absolute;
//     right: -90px;
//     top: -200px;
//     & h3 {
//       font-size: 1rem;
//       transform: rotate(-90deg);
//       font-weight: normal;
//     }
//   }
// }
