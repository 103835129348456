body {
  font-family: "neue-haas-unica", sans-serif;
  background-color: #efefef;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 400; }

a {
  color: #8f1300;
  transition: 0.2s all ease-in-out;
  text-decoration: none; }
  a:hover {
    transition: 0.2s all ease-in-out;
    color: #f2a78f; }

ul {
  padding-left: 1rem; }

.max-container {
  max-width: 1350px;
  margin-left: auto;
  margin-right: auto; }
  @media screen and (max-width: 767px) {
    .max-container {
      margin-left: 4%;
      margin-right: 4%; } }

.pad-b-min {
  padding-bottom: 2rem; }

.mar-b-min {
  margin-bottom: 2rem; }

.pad-b {
  padding-bottom: 6rem; }

.mar-b {
  margin-bottom: 6rem; }

.pad-b-max {
  padding-bottom: 9rem; }

.mar-b-max {
  margin-bottom: 9rem; }

.pad-t-min {
  padding-top: 2rem; }

.mar-t-min {
  margin-top: 2rem; }

.pad-t {
  padding-top: 6rem; }
  @media screen and (max-width: 767px) {
    .pad-t {
      padding: 2rem 0; } }

.mar-t {
  margin-top: 6rem; }

.pad-t-max {
  padding-top: 9rem; }

.mar-t-max {
  margin-top: 9rem; }

.pad-min {
  padding: 2rem 0; }
  @media screen and (max-width: 767px) {
    .pad-min {
      padding: 1.5rem 0; } }

.pad {
  padding: 6rem 0; }
  @media screen and (max-width: 767px) {
    .pad {
      padding: 2rem 0; } }

.pad-max {
  padding: 9rem 0; }
  @media screen and (max-width: 767px) {
    .pad-max {
      padding: 6rem 0; } }

.verde-whatsapp {
  color: #128c7e; }

.color-primario {
  background-color: #f7dbcc; }

.color-secundario {
  background-color: #f2a78f; }

.bg-primario {
  background-color: #f7dbcc; }

.bg-gris-light {
  background-color: rgba(0, 0, 0, 0.1); }

/* SCSS Headers
--------------------------------------------------- */
.header {
  padding: 2rem 3rem;
  position: sticky;
  top: 0;
  background-color: #efefef;
  transition: 0.2s all ease-in-out;
  z-index: 3; }
  @media screen and (max-width: 767px) {
    .header {
      padding: 1rem 1rem; } }
  @media screen and (max-width: 575px) {
    .header--pages {
      background-color: #f7dbcc; } }
  .header--sticky {
    background-color: white;
    padding: 0.5rem 3rem;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    transition: 0.2s all ease-in-out; }
    @media screen and (max-width: 767px) {
      .header--sticky {
        padding: 0.5rem 1rem; } }
    .header--sticky .header__logo {
      transition: 0.2s all ease-in-out; }
      .header--sticky .header__logo img {
        transition: 0.2s all ease-in-out; }
        @media screen and (max-width: 767px) {
          .header--sticky .header__logo img {
            width: 35px;
            height: auto;
            transition: 0.2s all ease-in-out; } }
  .header__navbar {
    display: flex;
    align-items: center; }
  .header__left {
    display: flex; }
  .header__right {
    display: flex;
    align-items: center;
    margin-left: auto; }
  .header__burger {
    font-size: 30px;
    font-weight: 700; }
  .header__logo {
    display: flex;
    align-items: center; }
    .header__logo img {
      width: 48px;
      height: auto;
      transition: 0.2s all ease-in-out; }
  .header__nav {
    margin-left: 3rem;
    display: flex; }
    @media screen and (max-width: 767px) {
      .header__nav {
        display: none; } }

.left-title {
  position: absolute;
  display: inline-flex;
  top: 13.5rem;
  text-align: right;
  left: -44px;
  font-size: 1rem;
  transform: rotate(-90deg);
  font-weight: normal; }
  @media screen and (max-width: 1100px) {
    .left-title {
      display: none; } }

.menu-principal {
  display: flex;
  align-items: center;
  margin-top: 0.6rem;
  list-style: none; }
  .menu-principal .menu-item.current-menu-item a {
    color: #8f1300; }
  .menu-principal .menu-item a {
    padding: 0rem 1rem;
    font-weight: normal;
    color: rgba(0, 0, 0, 0.9); }
    .menu-principal .menu-item a:hover {
      color: #8f1300; }

.btn-overlay {
  width: auto;
  height: auto;
  cursor: pointer;
  display: inline-block; }

.body-scroll {
  overflow: hidden; }

.m-overlay {
  display: none;
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  background: #2e2e2e;
  color: white;
  overflow: auto;
  z-index: 10;
  padding: 5rem; }
  @media screen and (max-width: 575px) {
    .m-overlay {
      padding: 1.5rem; } }
  .m-overlay__header {
    display: flex;
    justify-content: flex-end;
    align-items: center; }
  .m-overlay__close {
    position: absolute;
    top: 2rem;
    right: 3rem; }
    @media screen and (max-width: 767px) {
      .m-overlay__close {
        top: 0.5rem;
        right: 1.5rem; } }
    .m-overlay__close .bi {
      font-size: 4rem;
      cursor: pointer; }
  .m-overlay__menu {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    width: 100%;
    position: relative;
    margin-top: 4rem; }
  .m-overlay__nav {
    padding-left: 0;
    list-style: none;
    margin-bottom: 6rem;
    text-align: center; }
    @media screen and (max-width: 575px) {
      .m-overlay__nav {
        margin-bottom: 4rem; } }
    @media screen and (max-width: 375px) {
      .m-overlay__nav {
        margin-bottom: 1rem; } }
    .m-overlay__nav li {
      margin-bottom: 1rem; }
      @media screen and (max-width: 575px) {
        .m-overlay__nav li {
          margin-bottom: 1rem; } }
      .m-overlay__nav li a {
        color: white;
        text-decoration: none;
        font-size: 6rem;
        line-height: 1.2;
        font-weight: bold; }
        @media screen and (max-width: 767px) {
          .m-overlay__nav li a {
            font-size: 3rem; } }
        @media screen and (max-width: 375px) {
          .m-overlay__nav li a {
            font-size: 2rem;
            font-family: "neue-haas-unica", sans-serif; } }
        .m-overlay__nav li a .sub {
          display: block;
          font-size: 1.2rem;
          font-weight: 400;
          color: #f7dbcc; }
          @media screen and (max-width: 767px) {
            .m-overlay__nav li a .sub {
              font-size: 1rem; } }

.footer {
  background-color: #f2a78f;
  color: white;
  text-align: center; }
  @media screen and (max-width: 767px) {
    .footer {
      font-size: 15px; } }
  @media screen and (max-width: 400px) {
    .footer {
      font-size: 13px; } }
  .footer__widget {
    margin-bottom: 1rem; }
  .footer__copy a {
    color: white;
    font-weight: normal; }
    .footer__copy a:hover {
      color: #8f1300; }
  .footer__copy p {
    margin-bottom: 0rem; }
    @media screen and (max-width: 767px) {
      .footer__copy p {
        margin-bottom: 0px; } }
  .footer__links a {
    color: white;
    font-weight: normal; }
    .footer__links a:hover {
      color: #8f1300; }
  .footer__logos {
    padding: 2rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap; }
    .footer__logos img {
      margin: 0 1rem 1rem 1rem;
      width: auto;
      height: 33px;
      filter: invert(1) brightness(200%); }
  .footer .social-icon {
    background-color: rgba(0, 0, 0, 0.9) !important;
    width: 10px;
    height: auto; }

.page-title__container {
  padding: 0rem; }

.page-title__content {
  background-color: #f7dbcc;
  padding: 2rem 3rem; }
  @media screen and (max-width: 767px) {
    .page-title__content {
      padding: 1rem 1rem; } }
  @media screen and (max-width: 767px) {
    .page-title__content {
      padding-top: 0rem; } }

.page-title__h {
  font-size: 3rem; }
  @media screen and (max-width: 767px) {
    .page-title__h {
      font-size: 3rem;
      font-weight: bold; } }

.page-title__breadcrumbs {
  font-size: 0.8rem; }

/* SCSS Botones
--------------------------------------------------- */
.button {
  padding: 0.7rem 2rem;
  display: inline-flex;
  align-items: center;
  transition: 0.2s all ease-in-out;
  font-size: 1rem;
  font-weight: normal; }
  .button:hover {
    transition: 0.2s all ease-in-out; }
  .button i {
    margin-left: 6px; }

.button--principal {
  background-color: #f7dbcc;
  margin-right: 2rem;
  color: rgba(0, 0, 0, 0.9); }
  .button--principal:hover {
    color: rgba(0, 0, 0, 0.9);
    background-color: #f0bca0; }

.button--reservas {
  background-color: #f2a78f;
  margin-right: 2rem;
  color: white; }
  .button--reservas:hover {
    color: white !important;
    background-color: #ed8361; }

@media screen and (max-width: 767px) {
  .button--sm {
    padding: 0.4rem 1.5rem;
    margin-right: 1rem; } }

.button--whats {
  background-color: #00bb2d;
  margin-right: 2rem;
  color: white; }
  .button--whats:hover {
    background-color: #00bb2d;
    color: white;
    filter: brightness(0.9); }

.link-arrow {
  display: inline-flex;
  align-items: center; }
  .link-arrow i {
    margin-left: 6px; }
  .link-arrow--red {
    color: #8f1300; }

.hero {
  padding: 8rem 0; }
  @media screen and (max-width: 767px) {
    .hero {
      padding: 2rem 0 3rem; } }
  .hero__container {
    height: 100%;
    width: 80%; }
    @media screen and (max-width: 767px) {
      .hero__container {
        width: 100%; } }
  .hero__restaurante {
    font-size: 1rem;
    margin-bottom: none;
    color: #f2a78f; }
    @media screen and (min-width: 992px) {
      .hero__restaurante {
        display: none; } }
  .hero__claim {
    width: 50%;
    margin-bottom: 2rem;
    font-size: 9rem;
    font-weight: bold;
    line-height: 1;
    margin-top: 0; }
    @media screen and (max-width: 767px) {
      .hero__claim {
        margin-top: 1rem;
        margin-bottom: 1rem;
        width: 100%;
        font-size: 3rem; } }
  .hero__actions {
    width: auto;
    margin-bottom: 3rem; }
    @media screen and (max-width: 767px) {
      .hero__actions {
        margin-bottom: 2rem; } }
    .hero__actions a {
      color: rgba(0, 0, 0, 0.9);
      font-weight: normal;
      padding: 0rem 1rem; }
      @media screen and (max-width: 767px) {
        .hero__actions a {
          padding: 0rem 0.5rem; } }
      .hero__actions a:hover {
        color: #8f1300; }
    .hero__actions a:first-child {
      padding-left: 0rem; }
  .hero__descripcion {
    font-size: 1.8rem;
    width: 80%;
    font-weight: normal; }
    @media screen and (max-width: 767px) {
      .hero__descripcion {
        width: 100%;
        font-size: 1.5rem;
        color: rgba(0, 0, 0, 0.8); } }

.cta {
  padding: 6rem 0; }
  @media screen and (max-width: 767px) {
    .cta {
      padding: 3rem 0; } }
  .cta__content {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%; }
  .cta__titulo {
    font-size: 2.5rem;
    margin-bottom: 1rem; }
    @media screen and (max-width: 767px) {
      .cta__titulo {
        font-size: 2.2rem; } }
    @media screen and (max-width: 400px) {
      .cta__titulo {
        font-size: 2rem; } }
    .cta__titulo span {
      padding: 0rem 1rem; }
      @media screen and (max-width: 767px) {
        .cta__titulo span {
          padding: 0rem 0.5rem; } }
      @media screen and (max-width: 400px) {
        .cta__titulo span {
          padding: 0rem 0.5rem; } }
  .cta__links {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem; }
    @media screen and (max-width: 767px) {
      .cta__links {
        width: 100%;
        flex-direction: column; } }
  .cta__link {
    margin: 0 1rem 0.5rem;
    display: inline-block; }
  .cta__link--02 a {
    color: #1e8479; }
  .cta__link--03 a {
    color: rgba(0, 0, 0, 0.9); }
  .cta hr {
    color: rgba(0, 0, 0, 0.9);
    width: 10%;
    height: 2px;
    margin-bottom: 2rem; }

.wpcf7 h3 {
  font-size: 1.2rem;
  margin-bottom: 1.2rem; }

.wpcf7 fieldset {
  margin-bottom: 1.2rem; }

.wpcf7 .wpcf7-acceptance {
  font-size: 0.7rem;
  color: rgba(0, 0, 0, 0.3); }
  .wpcf7 .wpcf7-acceptance a {
    color: rgba(0, 0, 0, 0.3);
    font-weight: 400;
    text-decoration: underline; }

.wpcf7 .wpcf7-submit {
  border: none;
  transition: 0.2s all ease-in-out; }
  .wpcf7 .wpcf7-submit[disabled=""] {
    color: rgba(0, 0, 0, 0.3);
    background-color: rgba(0, 0, 0, 0.1);
    transition: 0.2s all ease-in-out; }
  @media screen and (max-width: 767px) {
    .wpcf7 .wpcf7-submit {
      display: block;
      width: 100%;
      text-align: center;
      padding: 1.2rem 2rem;
      font-size: 1.2rem; } }

.wpcf7 .wpcf7-response-output {
  margin: 1em 0 1em !important;
  padding: 0.5em 1em !important;
  border-radius: 8px !important; }

.form-control,
.form-select {
  padding: 0.675rem 0.75rem;
  font-size: 1rem !important; }

::-webkit-input-placeholder {
  /* WebKit browsers */ }

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */ }

::-moz-placeholder {
  /* Mozilla Firefox 19+ */ }

:-ms-input-placeholder {
  /* Internet Explorer 10+ */ }

body:not(.page-template-contacto) .grecaptcha-badge {
  visibility: hidden; }

.identidad {
  background: linear-gradient(90deg, #f7dbcc 0%, #f7dbcc 70%, #efefef 70%, #efefef 100%); }
  .identidad__content {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    @media screen and (max-width: 767px) {
      .identidad__content {
        flex-direction: column-reverse; } }
  .identidad__claim {
    margin-left: 10%; }
    @media screen and (max-width: 767px) {
      .identidad__claim {
        margin-left: 0%; } }
  @media screen and (max-width: 767px) {
    .identidad__imagen {
      margin-bottom: 1rem; } }
  .identidad__imagen img {
    width: 100%;
    height: auto; }
  .identidad__info {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start; }
    @media screen and (max-width: 767px) {
      .identidad__info {
        display: flex;
        flex-direction: column-reverse;
        gap: 1rem; } }
    .identidad__info p {
      display: inline-block;
      width: 50%; }
      @media screen and (max-width: 767px) {
        .identidad__info p {
          width: 100%; } }
  .identidad__titulo {
    font-size: 5rem;
    line-height: 1;
    font-weight: bold;
    width: 40%;
    margin-bottom: 2rem; }
    @media screen and (max-width: 767px) {
      .identidad__titulo {
        font-size: 3rem;
        margin-bottom: 1rem; } }
  .identidad__imagen-left {
    right: 92%;
    position: absolute; }
    @media screen and (max-width: 767px) {
      .identidad__imagen-left {
        display: none; } }

.detalles__container {
  margin-left: 10%; }
  @media screen and (max-width: 767px) {
    .detalles__container {
      margin-left: 0%; } }

.detalles__content {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  @media screen and (max-width: 767px) {
    .detalles__content {
      flex-direction: column-reverse; } }

.detalles__info {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap; }
  @media screen and (max-width: 767px) {
    .detalles__info {
      display: flex;
      flex-direction: column-reverse;
      gap: 1rem; } }
  .detalles__info p {
    display: inline-block;
    width: 60%; }
    @media screen and (max-width: 767px) {
      .detalles__info p {
        width: 80%; } }

.detalles__imagen {
  margin-bottom: 1rem; }
  .detalles__imagen img {
    width: 100%;
    height: auto; }

.detalles__titulo {
  font-size: 5rem;
  line-height: 1;
  font-weight: bold;
  width: 40%;
  margin-bottom: 2rem; }
  @media screen and (max-width: 767px) {
    .detalles__titulo {
      font-size: 3rem;
      margin-bottom: 1rem; } }

.rafel {
  background: linear-gradient(90deg, #efefef 0%, #efefef 40%, #f7dbcc 40%, #f7dbcc 100%); }
  @media screen and (max-width: 767px) {
    .rafel {
      background: #f7dbcc; } }
  .rafel__container {
    margin-left: 10%; }
    @media screen and (max-width: 767px) {
      .rafel__container {
        margin-left: 0%; } }
  .rafel__claim {
    width: 70%;
    font-size: 3.3rem;
    margin-bottom: 2rem; }
    @media screen and (max-width: 767px) {
      .rafel__claim {
        font-size: 2.2rem;
        width: 100%; } }
    .rafel__claim h3 {
      display: inline-block;
      display: flex;
      justify-content: center;
      flex-direction: column; }
    .rafel__claim span {
      font-weight: bold;
      display: inline-block; }
  .rafel__links {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 70%;
    margin-bottom: 5rem; }
    @media screen and (max-width: 767px) {
      .rafel__links {
        width: 100%; } }
    .rafel__links a:nth-child(2) {
      color: rgba(0, 0, 0, 0.9);
      font-weight: normal; }
      .rafel__links a:nth-child(2):hover {
        color: #8f1300; }
  .rafel__content {
    width: 90%;
    display: grid;
    gap: 2rem;
    grid-template-columns: auto 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: "a a b" "a a c"; }
    @media screen and (max-width: 767px) {
      .rafel__content {
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        gap: 1rem; } }
  .rafel .item1 {
    grid-area: a; }
    @media screen and (max-width: 767px) {
      .rafel .item1 {
        display: none; } }
    .rafel .item1 img {
      width: 100%;
      height: 550px;
      object-fit: cover; }
  .rafel .item2 {
    grid-area: b;
    text-align: right; }
    @media screen and (max-width: 767px) {
      .rafel .item2 {
        text-align: left; } }
    .rafel .item2 p {
      display: block;
      text-align: right; }
      @media screen and (max-width: 767px) {
        .rafel .item2 p {
          text-align: left;
          margin-left: 0%;
          width: 70%; } }
    .rafel .item2 a {
      display: inline-block; }
  .rafel__titulo {
    font-size: 5rem;
    line-height: 1;
    font-weight: bold;
    width: 40%;
    margin-bottom: 2rem;
    width: 100%; }
    @media screen and (max-width: 767px) {
      .rafel__titulo {
        font-size: 3rem;
        margin-bottom: 1rem; } }
  .rafel .item3 {
    grid-area: c; }
    @media screen and (max-width: 767px) {
      .rafel .item3 {
        margin-bottom: 1.5rem; } }
    .rafel .item3 img {
      width: 100%;
      height: auto; }

.bi .bi-chevron-right {
  font-weight: bold !important; }

.contacto__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem; }
  @media screen and (max-width: 767px) {
    .contacto__container {
      display: flex;
      flex-direction: column;
      gap: 2rem; } }

.contacto__titulo {
  margin-bottom: 2rem; }
  .contacto__titulo h3 {
    width: 100%;
    margin-bottom: 0.5rem;
    font-size: 2rem;
    line-height: 1;
    font-weight: bold;
    color: #f2a78f; }
    @media screen and (max-width: 767px) {
      .contacto__titulo h3 {
        margin-bottom: 0.3rem; } }
    @media screen and (max-width: 400px) {
      .contacto__titulo h3 {
        font-size: 2.3rem; } }
  .contacto__titulo p {
    color: rgba(0, 0, 0, 0.3); }

.contacto__content {
  margin-bottom: 3rem; }

.contacto__item {
  margin-bottom: 1.5rem; }
  .contacto__item ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none; }
    .contacto__item ul li span {
      display: inline-block;
      min-width: 100px; }
  .contacto__item h3 {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    font-weight: bold; }

.map iframe {
  filter: grayscale(100%); }

.cocina__container {
  padding: 2rem 3rem;
  width: 100%; }
  @media screen and (max-width: 767px) {
    .cocina__container {
      padding: 1rem 1rem; } }

.cocina__content {
  display: flex;
  justify-content: flex-start; }

.cocina__claim {
  width: 70%; }
  @media screen and (max-width: 991px) {
    .cocina__claim {
      width: 100%; } }
  .cocina__claim h3 {
    font-size: 3.3rem; }
    @media screen and (max-width: 767px) {
      .cocina__claim h3 {
        font-size: 2.5rem;
        margin-bottom: 0rem; } }
    @media screen and (max-width: 400px) {
      .cocina__claim h3 {
        font-size: 2rem;
        margin-bottom: 0rem; } }
    .cocina__claim h3 span {
      font-weight: bold;
      display: inline-block; }

.cocina-identidad {
  background: linear-gradient(90deg, #efefef 0%, #efefef 40%, #f7dbcc 40%, #f7dbcc 100%); }
  .cocina-identidad__container {
    width: 100%; }
  .cocina-identidad__content {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .cocina-identidad__foto {
    margin-bottom: 2.5rem; }
    .cocina-identidad__foto img {
      width: 100%;
      height: auto; }
  .cocina-identidad__info {
    max-width: 775px; }
  .cocina-identidad__titulo {
    font-size: 5rem;
    line-height: 1;
    font-weight: bold;
    width: 40%;
    margin-bottom: 2rem;
    width: 100%; }
    @media screen and (max-width: 767px) {
      .cocina-identidad__titulo {
        font-size: 3rem;
        margin-bottom: 1rem; } }
  .cocina-identidad__texto {
    width: 80%; }
    @media screen and (max-width: 767px) {
      .cocina-identidad__texto {
        width: 100%; } }
    .cocina-identidad__texto br {
      margin-bottom: 1rem; }

.galeria__content {
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(4, 1fr); }
  @media screen and (max-width: 767px) {
    .galeria__content {
      grid-template-columns: repeat(2, 1fr);
      gap: 4px; } }

.galeria__item img {
  width: 100%;
  height: 100%;
  object-fit: cover; }

@media screen and (min-width: 992px) {
  .galeria__item--1 {
    grid-column: 1 / 3;
    grid-row: 1 / 3; } }

@media screen and (min-width: 992px) {
  .galeria__item--2 {
    grid-column: 3 / 5;
    grid-row: 2 / 4; } }

@media screen and (min-width: 992px) {
  .galeria__item--3 {
    grid-column: 2 / 3;
    grid-row: 3 / 5; } }

@media screen and (max-width: 767px) {
  .idiomas {
    display: grid;
    grid-template-columns: 1fr 1fr; } }

.idiomas li button {
  background-color: rgba(0, 0, 0, 0.3) !important;
  color: white;
  border: 0 !important;
  display: flex;
  align-items: center; }
  @media screen and (max-width: 767px) {
    .idiomas li button {
      width: 100%;
      text-align: center;
      justify-content: center; } }
  .idiomas li button.active {
    background-color: #f2a78f !important;
    color: white !important; }
  .idiomas li button img {
    width: 22px;
    height: auto;
    margin-right: 0.5rem;
    border: 1px solid white;
    border-radius: 50px;
    padding: 2px; }

.carta__container {
  padding: 2rem 3rem;
  width: 100%; }
  @media screen and (max-width: 767px) {
    .carta__container {
      padding: 1rem 1rem; } }

.carta__content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 4rem !important;
  gap: 10rem;
  margin-bottom: 4rem; }
  @media screen and (max-width: 992px) {
    .carta__content {
      display: flex;
      flex-direction: column;
      row-gap: 2.5rem !important; } }

.carta__item {
  background-color: white;
  padding: 2rem;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1); }
  @media screen and (max-width: 767px) {
    .carta__item {
      padding: 1rem; } }

.carta__titulo {
  font-size: 3rem;
  line-height: 1;
  font-weight: bold;
  margin-bottom: 2rem; }
  @media screen and (max-width: 767px) {
    .carta__titulo {
      font-size: 2.5rem;
      margin-bottom: 1rem;
      color: #f2a78f; } }
  .carta__titulo small {
    font-size: 50%; }

.carta__logo {
  width: 130px;
  height: auto; }

.carta__plato {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  border-bottom: dotted 4px #f7dbcc; }
  @media screen and (max-width: 767px) {
    .carta__plato {
      font-size: 14px; } }
  .carta__plato:last-child {
    border-bottom: none; }
  .carta__plato span {
    font-size: 0.8125rem;
    font-weight: normal; }

.carta__recomendacion span {
  background-color: #ffb700;
  font-size: 0.95rem;
  padding: 2px 5px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  color: #fff;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 5px; }

.carta__nombre {
  width: 80%;
  font-weight: bold; }
  @media screen and (max-width: 767px) {
    .carta__nombre {
      font-size: 1.1rem;
      line-height: 1.2; } }

.carta__vino {
  font-size: 1rem;
  font-weight: 400;
  margin-top: 5px;
  color: rgba(0, 0, 0, 0.7); }
  .carta__vino-do {
    margin-top: 4px;
    text-transform: uppercase; }

.carta__menu-tipo {
  margin-bottom: 1rem;
  padding: 1rem 0;
  border-bottom: dotted 4px #f7dbcc; }

.carta__menu-tipo-titulo {
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  font-size: 1.2rem;
  letter-spacing: 1px;
  color: #f2a78f;
  font-weight: bold; }
  @media screen and (max-width: 767px) {
    .carta__menu-tipo-titulo {
      margin-bottom: 0.8rem; } }

.carta__menu-tipo-plato {
  margin-bottom: 10px; }
  @media screen and (max-width: 767px) {
    .carta__menu-tipo-plato {
      margin-bottom: 12px;
      font-size: 1.1rem;
      line-height: 1.2; } }

.alergenos {
  text-align: center;
  margin-top: 3rem; }
  @media screen and (min-width: 576px) {
    .alergenos {
      display: none; } }
