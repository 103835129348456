body {
  font-family: $typo-principal;
  background-color: $color-background-light;
  font-size: $font-size;
  color: $color-gris-03;
  font-weight: $font-weight;
}

a {
  color: $color-alternativo;
  transition: $trans;
  text-decoration: none;
  &:hover {
    transition: $trans;
    color: $color-principal-dark;
  }
  // &::after {
  //   content: " >";
  // }
}

ul {
  padding-left: 1rem;
}

.max-container {
  max-width: 1350px;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: 767px) {
    margin-left: 4%;
    margin-right: 4%;
  }
}
