$color-principal: #f7dbcc;
$color-principal-dark: #f2a78f;
$color-alternativo: #8f1300;

$color-gris-01: rgba(0, 0, 0, 0.1);
$color-gris-02: rgba(0, 0, 0, 0.3);
$color-gris-03: rgba(0, 0, 0, 0.8);
$color-gris-04: rgba(0, 0, 0, 0.9);

$color-background-light: #efefef;
$color-background: rgba(255, 255, 255, 1);

$color-whatsapp: #128c7e;

$font-size-big: 1.2rem;
$font-size: 1rem;
$font-size-small: 0.8rem;
$font-size-smaller: 0.7rem;

$font-weight: 400;

$box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);

$border-radius: $font-size / 2;

$trans: 0.2s all ease-in-out;

$sp-1: 2rem;
$sp-2: 6rem;
$sp-3: 9rem;

$typo-principal: "neue-haas-unica", sans-serif;
$typo-secundaria: "Georgia", serif;
