.footer {
  background-color: $color-principal-dark;
  color: $color-background;
  text-align: center;
  @media screen and (max-width: 767px) {
    font-size: 15px;
  }
  @media screen and (max-width: 400px) {
    font-size: 13px;
  }
  &__widget {
    margin-bottom: 1rem;
  }
  &__copy {
    a {
      color: $color-background;
      font-weight: normal;
      &:hover {
        color: $color-alternativo;
      }
    }
    p {
      margin-bottom: 0rem;
      @media screen and (max-width: 767px) {
        margin-bottom: 0px;
      }
    }
  }
  &__links {
    a {
      color: $color-background;
      font-weight: normal;
      &:hover {
        color: $color-alternativo;
      }
    }
  }
  &__logos {
    padding: $sp-1 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    img {
      margin: 0 1rem 1rem 1rem;
      width: auto;
      height: 33px;
      filter: invert(1) brightness(200%);
    }
  }

  .social-icon {
    background-color: $color-gris-04 !important;
    width: 10px;
    height: auto;
  }
}
